import React, { useEffect, useState } from 'react';
import './List.css';
import { Box, Button, CircularProgress, FormControl, IconButton, ImageList, ImageListItem, ImageListItemBar, InputLabel, MenuItem, Select, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { IExternalPet } from '../../interface/external-pet';
import InfoIcon from '@mui/icons-material/Info';
import axios from 'axios';
import { Add } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { colors, species, statuses, statusesMapper } from '../../utils/const';
import InfiniteScroll from "react-infinite-scroll-component";

const List = () => {
  
  useEffect(() => {
    filterPets();
  }, []);

  const limit = 30;

  const [ page, setPage ] = useState(1);
  const [ totalPages, setTotalPages ] = useState(0);
  const [ pets, setPets ] = useState<Array<IExternalPet>>([]);

  const defaultFilters = {
    name: '',
    phone: '',
    breed: '',
    species: '',
    color: '',
    description: '',
    lat: '',
    lon: '',
    city: '',
    status: ''
  };

  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('sm'));

  const navigate = useNavigate();

  const [filter, setFilter] = useState(defaultFilters);

  const filterPets = async () => {

    const params = setParams(true);
    const responsePets = await getPets(params);

    setPets(responsePets);
  }

  const loadMore = async () => {
    const params = setParams();
    const responsePets = await getPets(params);

    setPets([...pets, ...responsePets]);
  }

  const getPets = async (params: URLSearchParams) => {
    const response = await axios.get('/external-pets', {
      baseURL: process.env.REACT_APP_API_URL,
      params
    });
    setPage(response.data.meta.currentPage+1);
    setTotalPages(response.data.meta.totalPages);

    return response.data.items;
  }

  const setParams = (reset: boolean = false) => {
    const params = new URLSearchParams();

    params.append('page', reset ? '1' : page.toString());
    params.append('limit', limit.toString());

    if (filter.breed.length) params.append('breed', filter.breed);
    if (filter.color.length) params.append('color', filter.color);
    if (filter.species.length) params.append('species', filter.species);
    if (filter.status.length) params.append('status', statusesMapper[statuses.findIndex(s => s === filter.status)]);

    return params;
  }

  return (
    <Stack spacing={{ xs: 1, sm: 2 }} direction={ { sm: 'column', md: 'row', lg: 'row' } }>
      
      <Box
          component="form"
          sx={{
            '& > :not(style)': { m: 1, width: '30ch' },
            marginTop: '7px !important'
          }}
          noValidate
          autoComplete="off"
          id='form'
          display="flex"
          flexDirection="column"
          justifyContent="start"
          alignItems="center"
          onSubmit={(ev) => {
            ev.preventDefault();
            filterPets();
          }}
        >

          <FormControl fullWidth>
            <InputLabel id="status-label">Situação *</InputLabel>
            <Select
              labelId="status-label"
              id="status"
              value={filter.status}
              label="Situação *"
              onChange={(ev) => setFilter({...filter, status: ev.target.value})}
            >
              { statuses.map(status => (
                <MenuItem value={status}>{status}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField id="outlined-basic" onChange={(ev) => setFilter({...filter, breed: ev.target.value})} label="Raça" placeholder='SRD' variant="outlined" />
          <FormControl fullWidth>
            <InputLabel id="species-label">Espécie</InputLabel>
            <Select
              labelId="species-label"
              id="species"
              value={filter.species}
              label="Espécie"
              onChange={(ev) => setFilter({...filter, species: ev.target.value})}
            >
              { species.map(type => (
                <MenuItem value={type}>{type}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="color-label">Cor predominante</InputLabel>
            <Select
              labelId="color-label"
              id="color"
              value={filter.color}
              label="Cor predominante"
              onChange={(ev) => setFilter({...filter, color: ev.target.value})}
            >
              { colors.map(color => (
                <MenuItem value={color}>{color}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button variant="contained" type='submit' form='form'>
            Filtrar
          </Button>
          <Button variant="outlined" type='button' onClick={() => { 
            setFilter(defaultFilters);
          }} form='form'>
            Limpar filtros
          </Button>
        </Box>
        <Box sx={{ overflowY: 'scroll' }}>
          <div style={{textAlign: 'right', marginRight: '16px'}}>
            <Button sx={{ marginTop: '15px' }} startIcon={<Add />} variant='contained' onClick={() => navigate('/cadastro')}>Cadastrar pet</Button>
          </div>
          <InfiniteScroll
              dataLength={page * limit}
              next={loadMore}
              hasMore={totalPages >= page}
              loader={<CircularProgress color='primary' />}
              endMessage={<Typography component="h4" textAlign="center" width="100%">Este é o fim da lista</Typography>}
          >
            <ImageList variant="masonry" cols={ matchDownMd ? 2 : 4} gap={ matchDownMd ? 6 : 12}>
              {pets.map((pet: IExternalPet) => (
                <ImageListItem onClick={() => navigate(`/detalhes/${pet.publicId}`)} key={pet.publicId}>
                  <img
                    srcSet={`${pet.pictureUrl}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    src={`${pet.pictureUrl}?w=248&fit=crop&auto=format`}
                    alt={`Entre em contato com ${pet.phone} e saiba mais`}
                    loading="lazy"
                  />
                  <ImageListItemBar
                    title={`${pet.species} (${pet.color})`}
                    subtitle={`${statuses[statusesMapper.findIndex(s => s === pet.status)]}`}
                    actionIcon={
                      <IconButton
                        sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                        aria-label={`Entre em contato com ${pet.phone} e saiba mais`}
                      >
                        <InfoIcon />
                      </IconButton>
                    }
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </InfiniteScroll>
        </Box>
    </Stack>
  )
}

export default List;
