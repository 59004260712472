import React from 'react'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

type Props = {
    center: {
        lat: number;
        lng: number;
    }
    width: string;
    heigt: string;
}

const Map = (props: Props) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-maps',
    googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY as string
  })

  const [map, setMap] = React.useState<google.maps.Map | null>(null)

  const onLoad = React.useCallback((map: google.maps.Map) => {
    map.setZoom(15);
    setMap(map);
  }, [])

  const onUnmount = React.useCallback((map: any) => {
    setMap(null);
  }, [])

  return isLoaded ? (
      <GoogleMap
        mapContainerStyle={{ width: props.width, height: props.heigt}}
        center={props.center}
        options={{
            gestureHandling: 'none',
            streetViewControl: false,
            mapTypeControl: false,
            zoomControl: false,
            fullscreenControl: false
        }}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        <Marker position={props.center}></Marker>
      </GoogleMap>
  ) : <></>
}

export default React.memo(Map)