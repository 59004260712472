import { Box, Button, CircularProgress, FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, Snackbar, TextField, styled, useMediaQuery, useTheme } from "@mui/material";

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useMemo, useState } from "react";
import { green } from '@mui/material/colors';
import MaskedInput from "../../components/masked-input";
import axios from "axios";
import { colors, species, statuses, statusesMapper } from "../../utils/const";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const CreatePage = () => {

    const [ message, setMessage ] = useState<string>('');
    const [submitting, setSubmitting] = useState(false);

    const defaultModel = {
      name: '',
      phone: '',
      breed: '',
      species: '',
      color: '',
      description: '',
      lat: '',
      lon: '',
      city: '',
      status: statuses[0]
    };

    const [model, setModel] = useState(defaultModel);
    
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('sm'));

    const [location, setLocation] = useState('location');

    const [picture, setPicture] = useState<File>();

    const isEnabled = useMemo(() => {
        return model.species && model.color && picture
    }, [ model, picture ]);

    const submit = (ev: React.FormEvent<HTMLFormElement>) => {
      setSubmitting(true);
      ev.preventDefault();
      
      if (navigator.geolocation && location === 'location') {
        return navigator.geolocation.getCurrentPosition((position) => {
          const _model = { ... model, lat: position.coords.latitude.toString(), lon: position.coords.longitude.toString() };
          return proceedSubmit(_model);
        } , errorLocation);
      }

      return proceedSubmit(model);
    }

    const proceedSubmit = async (data: any) => {
      const formData: FormData = new FormData();

      formData.append('file', picture as Blob, 'profile.png');
      Object.keys(data).forEach(key => {
        if ((data[key] as string).length) {
          
          if (key === 'status') {
            const status = data[key];
            formData.append(key, statusesMapper[statuses.findIndex(s => s === status)]);
          } else {
            formData.append(key, data[key]);
          }
        }
      });
      
      try {
        await axios.post('/external-pets', formData, {
          baseURL: process.env.REACT_APP_API_URL,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        setModel(defaultModel);
        setPicture(undefined);
        setMessage('Pet cadastrado com sucesso');
      } catch (e) {
        setMessage('Erro ao cadastrar pet');
      } finally {
        setSubmitting(false);
      }
    }

    const errorLocation = () => {
      setSubmitting(false);
      setMessage('Precisamos de sua localização ou mude a opção para digitar a cidade');
    }

    const setPictureFile = async (ev: React.ChangeEvent<HTMLInputElement>) => {
      const file = ev.target.files?.length ? ev.target.files[0] as any : null;
      if (file) {
        const compressedFile = await compressImage(file, { quality: 0.5 });
        setPicture(compressedFile);
      }
    }

    const compressImage = async (file: File, { quality = 1, type = file.type }) => {
      // Get as image data
      const imageBitmap = await createImageBitmap(file);

      // Draw to canvas
      const canvas = document.createElement('canvas');
      canvas.width = imageBitmap.width;
      canvas.height = imageBitmap.height;
      const ctx = canvas.getContext('2d');
      
      if (ctx) {
        ctx.drawImage(imageBitmap, 0, 0);

        // Turn into Blob
        const blob: Blob = await new Promise((resolve) =>
            canvas.toBlob((b) => resolve(b as Blob), type, quality)
        );

        // Turn Blob into File
        return new File([blob], file.name, {
            type: blob.type,
        });
      }
    };

    return (
      <Box
        component="form"
        sx={{
          '& > :not(style)': { m: 1 },
          marginTop: '7px !important'
        }}
        display='grid'
        gap={2}
        gridTemplateColumns="repeat(12, 1fr)"
        noValidate
        autoComplete="off"
        id='form'
        alignItems="center"
        onSubmit={submit}
      > 
        <Box gridColumn={ matchDownMd ? "span 12" : "span 6" }>
          <FormControl fullWidth>
            <InputLabel id="status-label">Situação *</InputLabel>
            <Select
              labelId="status-label"
              id="status"
              value={model.status}
              label="Situação *"
              onChange={(ev) => setModel({...model, status: ev.target.value})}
            >
              { statuses.map(status => (
                <MenuItem value={status}>{status}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box gridColumn={ matchDownMd ? "span 12" : "span 6" }>
          <FormControl fullWidth>
            <InputLabel id="species-label">Espécie *</InputLabel>
            <Select
              labelId="species-label"
              id="species"
              value={model.species}
              label="Espécie *"
              onChange={(ev) => setModel({...model, species: ev.target.value})}
            >
              { species.map(type => (
                <MenuItem value={type}>{type}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box gridColumn={ matchDownMd ? "span 12" : "span 6" }>
          <FormControl fullWidth>
            <InputLabel id="color-label">Cor predominante *</InputLabel>
            <Select
              labelId="color-label"
              id="color"
              value={model.color}
              label="Cor predominante *"
              onChange={(ev) => setModel({...model, color: ev.target.value})}
            >
              { colors.map(color => (
                <MenuItem value={color}>{color}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box gridColumn={ matchDownMd ? "span 12" : "span 6" }>
          <TextField fullWidth onChange={(ev) => setModel({...model, name: ev.target.value})} value={model.name} id="name" label="Apelido" variant="outlined" />
        </Box>
        <Box gridColumn={ matchDownMd ? "span 12" : "span 6" }>
          <TextField fullWidth onChange={(ev) => setModel({...model, phone: ev.target.value})}  InputProps={{ inputComponent: MaskedInput as any }} inputProps={{ mask: '(00) 00000-0000', definition: /[1-9]/ }} value={model.phone} id="phone" label="Telefone" variant="outlined" />
        </Box>

        <Box gridColumn={ matchDownMd ? "span 12" : "span 6" }>
          <TextField fullWidth onChange={(ev) => setModel({...model, breed: ev.target.value})} value={model.breed} id="breed" label="Raça" placeholder='SRD' variant="outlined" />
        </Box>

        <Box gridColumn={ matchDownMd ? "span 12" : "span 6" }>
          <TextField fullWidth onChange={(ev) => setModel({...model, description: ev.target.value})} value={model.description} id="description" label="Descrição/Características" multiline rows={4} maxRows={6} variant="outlined" />
        </Box>


        <Box gridColumn={ matchDownMd ? "span 12" : "span 6" }>
          <FormControl>
            <FormLabel id="city-or-location-label">Localização</FormLabel>
            <RadioGroup
              row
              aria-labelledby="city-or-location-label"
              onChange={(ev) => {
                setLocation(ev.currentTarget.value);
                setModel({...model, lat: '', lon: '', city: ''});
              }}
              value={location}
              name="radio-buttons-group"
            >
              <FormControlLabel value="location" control={<Radio />} label="Usar minha localização" />
              <FormControlLabel value="city" control={<Radio />} label="Cadastrar cidade" />
            </RadioGroup>
          </FormControl>

          { location !== 'location' && <TextField fullWidth onChange={(ev) => setModel({...model, city: ev.target.value})} value={model.city} id="city" label="Cidade" variant="outlined" /> }
        </Box>

        <Box gridColumn="span 12" display="flex" justifyContent="center">
          <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            disabled={submitting}
            startIcon={<CloudUploadIcon />}
            >
                Adicionar foto
                <VisuallyHiddenInput onChange={setPictureFile} accept="image/*" type="file" />
          </Button>
          <small style={{ textAlign: 'right', marginTop: '-5px', color: 'green'}}>{ picture?.name }</small>
        </Box>

        <Box gridColumn="span 12" display="flex" justifyContent="center" sx={{ m: 1, position: 'relative' }}>
          <Button color='primary' variant="contained" type='submit' form='form' disabled={submitting || !isEnabled}>
              Cadastrar
          </Button>
          { submitting && (
            <CircularProgress
              size={24}
              sx={{
                color: green[500],
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Box>
        <Snackbar
          open={!!message.length}
          onClose={() => setMessage('')}
          autoHideDuration={6000}
          message={message}
        />
      </Box>
    );
}

export default CreatePage;