import * as React from 'react';

import {
    Route,
    Routes,
} from "react-router-dom";
import List from './list/List';
import CreatePage from './create/Create';
import Layout from './Layout';
import Details from './details/Details';

const App = () => {

  return (
    <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<List />} />
          <Route path="cadastro" element={<CreatePage />} />
          <Route path="detalhes/:publicId" element={<Details />} />
        </Route>
    </Routes>
  );
}

export default App;