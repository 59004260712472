import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { IconButton, Stack, Toolbar } from '@mui/material';

import logo from '../logo.png';

import {
    Outlet,
    useLocation,
    useNavigate,
} from "react-router-dom";
import { ChevronLeft } from '@mui/icons-material';

const Layout = () => {

  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Stack direction="column" width='100vw'>
        <Toolbar sx={{ backgroundColor: '#7329B0' }}>
            { location.pathname !== '/' && (
              <IconButton onClick={() => navigate('/')}>
                <ChevronLeft htmlColor='white' />
              </IconButton>
            )}
            <img width="128px" style={{ cursor: 'pointer' }} src={logo} onClick={() => {
              navigate('/');
            }} />
        </Toolbar>
        <Outlet />
      </Stack>
    </Box>
  );
}

export default Layout;