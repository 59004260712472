import { Avatar, Box, Button, Chip, CircularProgress, Divider, List, ListItem, ListItemAvatar, ListItemText, ListSubheader, Modal, Stack, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { IExternalPet } from "../../interface/external-pet";
import { useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { Fingerprint, LocationCity, Pets, Phone, Place, Badge, Palette, Description } from "@mui/icons-material";
import Map from "../../components/map";
import { statuses, statusesMapper } from "../../utils/const";
import { ExternalPetStatus } from "../../enum/external-pet-status";

type Props = {
    
}
const Details = (props: Props) => {
    
    const { publicId } = useParams();

    const [ pet, setPet ] = useState<IExternalPet>();

    useEffect(() => {
        loadDetails();
    }, [publicId]);

    const loadDetails = async () => {
        const response = await axios.get(`/external-pets/${publicId}`, {
            baseURL: process.env.REACT_APP_API_URL,
        });
        setPet(response.data);
    }

    const statusColor = useMemo(() => {

        if (pet?.status === ExternalPetStatus.MISSING) {
            return 'error';
        } else if (pet?.status === ExternalPetStatus.SEARCHING_TUTOR) {
            return 'warning';
        } else if (pet?.status === ExternalPetStatus.FOUND) {
            return 'success';
        }

        return 'primary';
    }, [ pet ]);
    
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box sx={{ m: 5}}>
            { !!pet && (
                <Stack justifyContent="center" spacing={{ xs: 1, sm: 2 }} direction={ { sm: 'column', md: 'row', lg: 'row' } } divider={<Divider orientation={ matchDownMd ? 'horizontal' : "vertical"} flexItem />}>
                    
                    <Stack alignItems={matchDownMd ? 'center' : undefined}>
                        <img
                            style={{ objectFit: 'contain', marginRight: '1px', height: '200px', width: '200px', borderRadius: '50%'}}
                            src={pet.pictureUrl}
                        />
                        { pet.phone && <Button startIcon={<Phone />}>Entrar em contato</Button> }
                    </Stack>
                
                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                        <ListSubheader component="div">
                            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                                <Box component="div">Identificação: { pet.publicId }</Box>
                                <Chip
                                    label={statuses[statusesMapper.findIndex(s => s === pet.status)]}
                                    color={statusColor}
                                />
                            </Box>
                        </ListSubheader>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <Pets />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Espécie" secondary={pet.species} />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <Palette />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Cor" secondary={pet.color} />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <Fingerprint />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Raça" secondary={pet.breed ? pet.breed : 'Não informado'} />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <Badge />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Apelido" secondary={pet.name ? pet.name : 'Não informado'} />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <Phone />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Telefone para contato" secondary={pet.phone ? pet.phone : 'Não informado'} />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <Description />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Descrição/Características" secondary={pet.description ? pet.description : 'Não informado'} />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    { !pet.lat && <LocationCity /> }
                                    { pet.lat && <Place /> }
                                </Avatar>
                            </ListItemAvatar>
                            { !pet.lat && <ListItemText primary="Localização" secondary={pet.city ? pet.city : 'Não informado'} />}
                            { pet.lat && (
                                <Tooltip title="Clique para abrir a navegação">
                                    <Box sx={{ cursor: 'pointer' }} onClick={() => {
                                        window.open(`http://www.google.com/maps/place/${pet.lat},${pet.lon}`, '_blank');
                                    }}>
                                        <Map
                                            center={{ lat: pet.lat as number, lng: pet.lon as number }}
                                            width="600px"
                                            heigt="300px"
                                        />              
                                    </Box>
                                </Tooltip>
                            )}
                        </ListItem>
                    </List>
                </Stack>
            )}
            { !pet && <CircularProgress /> }
        </Box>
    );
}

export default Details;